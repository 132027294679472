body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Bariol-Regular';
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field, 
input[type="date"]:in-range::-webkit-datetime-edit-month-field, 
input[type="date"]:in-range::-webkit-datetime-edit-day-field, 
input[type="date"]:in-range::-webkit-datetime-edit-text { 
    color: transparent; 
}


@font-face {
    font-family: 'Bariol-Regular';
    src: url('./fonts/Bariol-Regular.woff2') format('woff2'),
        url('./fonts/Bariol-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BariolThin-Italic';
    src: url('./fonts/BariolThin-Italic.woff2') format('woff2'),
        url('./fonts/BariolThin-Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BariolBold-Italic';
    src: url('./fonts/BariolBold-Italic.woff2') format('woff2'),
        url('./fonts/BariolBold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bariol-Light';
    src: url('./fonts/Bariol-Light.woff2') format('woff2'),
        url('./fonts/Bariol-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BariolRegular-Italic';
    src: url('./fonts/BariolRegular-Italic.woff2') format('woff2'),
        url('./fonts/BariolRegular-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bariol-Bold';
    src: url('./fonts/Bariol-Bold.woff2') format('woff2'),
        url('./fonts/Bariol-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BariolLight-Italic';
    src: url('./fonts/BariolLight-Italic.woff2') format('woff2'),
        url('./fonts/BariolLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bariol-Thin';
    src: url('./fonts/Bariol-Thin.woff2') format('woff2'),
        url('./fonts/Bariol-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

.react-datepicker{
    display: inline-flex !important;
}
.flexdiv{
    display: flex;
    gap: 20px;
}

.react-datepicker-popper {
    z-index: 99 !important;
}