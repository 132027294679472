@import "../../styles/colors";

button {
  &:disabled {
    cursor: no-drop;
  }
}
.header-text-h3{ 
  font-size: 1.5rem;
}
.orange-txt {
  color: $sub-header;
}
.or-separator {
  margin: 1rem;
  font-size: 1.2rem;
  text-align: center;
}
.register {
  .step-count {
    font-size: 1rem;
    font-family: "Bariol-Bold";
  }
  .terms-container {
    background-color: $white;
    max-height: 20rem;
    overflow-y: auto;
    color: $secondary;
    padding: 0.8rem;
    border-radius: 3px;
    h3 {
      color: #000 !important;
    }
  }
  .end-date-field {
    &:disabled {
      cursor: not-allowed;
    }
  }
  .banner-container {
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .register-container {
    margin-bottom: 8rem;
    color: $white;
    padding: 1rem;
    background-color: rgba($color: $pink-dark, $alpha: 0.95);
    margin-top: -3rem;
    z-index: 2;
    position: relative;
    display: flex;
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.8rem;
      border-bottom: 2px solid $pink-light;
      padding-bottom: 1.5rem;
    }
    h3 {
      font-size: 1.5rem;
      color: $sub-header;
    }
    p {
      font-size: 1rem;
    }
    .register-content,
    .register-form {
      padding: 3.5rem 2rem;
      flex: 1;
      .form {
        .form-group {
          height: 65px;
          margin-bottom: 0;
          .form-label {
            &.mandatory {
              &::after{
                content: '*';
                font-size: 1.5rem;
                top: 5px;
                position: relative;
                left: 5px;
              }
            }
            margin-right: 0;
            padding-top: 0.1rem;
            padding-bottom: 0.3rem;
          }
          input[type=file] {
            font-size: 13px;
            &:disabled {
              cursor:not-allowed;
            }
          }
          .form-control {
            &:disabled {
              cursor:not-allowed;
            }
            height: 36px;
            border-radius: 0;
            border: none;
            color: #000;
            font-weight: 600;
            letter-spacing: 1px;
            &:focus {
              outline-color: $pink-light;
              outline-width: 2px;
            }
          }
          .form-icon {
            position: absolute;
            top: 8px;
            right: 25px;
            z-index: 10;
            color: $pink-dark;
            font-size: 1.3rem;
          }
        }
      }
      .actions {
        margin-top: 1.8rem;
        .form-btn {
          background-color: transparent !important;
          border: 0;
          padding: 0;
          font-size: 1.1rem !important;
          font-weight: 600;
          &:hover {
            border: 0;
            background-color: transparent !important;
          }
          svg {
            margin-top: -2px;
          }
        }
      }
    }
  }
}


// Login
.loginclassregister{
  padding: 40px;
  max-width: 500px;
  margin: 0 auto;
  border: 1px solid #efebeb;
  border-radius: 30px;
  h2{
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 30px;
      text-align: center;
  }
}
.inputflex{
  margin-bottom: 20px;
  position: relative;
  label{
    width: 100%;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
  }
  input{
    height: 48px;
    line-height: 58px;
    border-radius: 6px;
    padding: 3px 15px;
    border:1px solid #ccc;
    color: #000;
    width: 100%;
  }
}
.logologin{
  margin-bottom: 20px;
  text-align: center;
  img{
    max-width: 100px;
    height: auto;
  }

}
.buttonclass{
  padding:10px 30px;
  border: 1px solid #bb2249;
  background-color: #bb2249;
  color: #fff;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
    &:hover{
      background-color: #fff;
      color: #bb2249;
    }
    &:focus{
      background-color: #fff;
      color: #bb2249;
    }
}

.flexloginauth{
  margin: 60px 10px;
}

.wfull{
  width: 100%;
  display: block;
}

.inputflex.forgotpassowrd{
  text-align: right;
  a{
    display: inline-block;
    font-weight: 600;
  }
}
.mb-40{
  margin-bottom: 40px;
}
.titlepage{
  font-size: 24px;
  font-weight: bold;
  margin: 15px 0;
}
.filtersearch{
  position: relative;
  input{
    height: 38px;
    padding:3px 45px 3px 15px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
  }
  .btn-search{
    position: absolute;
    border: 0px;
    background-color: #fff;
    width: 34px;
    height: 34px;
    right: 2px;
    top: 2px;
    svg{
      width: 20px;
    }
  }
}
.inputflex .eyeicon{
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 40px;
  color: #a0a0a0;
}
.pr20{
  padding-right: 44px !important;
}
.mt30{margin-top: 40px;}
.inputflex .eyeicon svg{
display: block;
width: 24px;
}

.ilineflexusr{
  display: flex;
  gap: 10px;
}
.paginationlists{
  margin-bottom: 20px;
}
.paginationlists nav{
  display: flex !important;
  justify-content: end;
}
.paginationlists nav a{
min-width: 40px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
color: #000; border:1px solid #eaeaea;
margin: 2px;
border-radius: 4px;

}
.paginationlists nav a.activeclass{
  background-color: #af1b20;
  color: #fff;
}
.paginationlists nav a:hover, .paginationlists nav a:focus{
  color:#af1b20;
}
input[type="date"]:before {
  color: #969696;
  // content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.nodatafount-text{
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
input[type="date"]{
  position: relative;
}
// input[type="date"]::before{
//     color: #999;
//     content: attr(placeholder) " ";
//     position: absolute;
//     left: 10px;
//     top: 9px;z-index: 1;
//     font-size: 13px;
//     width: 150px;
// }
// input[type="date"]:focus::before {
//     content: "" !important;
// }

.errorred{
  color:#af1b20;
  font-size: 14px;
  width: 100%;
  display: block;
  margin-top: 2px;
}

.urllistname{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
table{
  border: 1px solid #e8e5e6;
}
.table .thead-dark th{
  color: #000;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.actionbtn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  &:hover{
    background-color: #9a1c20;
    color: #ffffff;
  }
  svg{
    width: 20px;
  }
}
.paginationlists{
  text-align: right;
  nav{
    display: inline-block;
  }
  .page-link{
    color: #9a1c20;
    font-weight: 600;
    &:hover{
      background-color: #9a1c20;
      color: #ffffff;
    }
  }
}

.flexdetails{
  // display: flex;
  // align-items: center;
  margin-bottom: 20px;
  .flexdleft{
    min-width: 200px;
    font-weight: 600;
  }
  
}
.inlineflexbutton{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  padding: 8px 26px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 30px;
}
.inlineflexbutton:hover{
  border: 1px solid #9a1c20;
  color: #9a1c20;
}
.inlineflexbutton svg{
  min-width: 20px;
}
.max-wdetails{
  // max-width: 1000px;
  margin: 20px auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 20px;
}
.userdetailsimg{
  width: 100%;
  // max-width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  margin:0 auto 30px;
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.vehicl-details-title{
  font-size: 32px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}


//  Responsive

@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  .banner-container {
    height: 200px !important;
  }
  .container {
    max-width: 950px;
    padding: 0;
    .register-form {
      h2 {
        padding-bottom: 0.5rem !important;
      }
    }
  }
  .register-content {
    padding: 0 2rem 0 1rem !important;
    h1 {
      font-size: 1.5rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
  .register-container {
    margin: 0 !important;
    .register-form {
      flex: 2 !important;
      padding: 0 !important;
    }
    h2 {
      font-size: 1.4rem !important;
    }
    h3 {
      font-size: 1.2rem !important;
      .step-count {
        font-size: 0.9rem !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  /* start of medium tablet styles */
  .banner-container {
    height: 160px !important;
  }
  .container {
    max-width: 767px;
    margin: 0;
  }
  .register-content {
    padding: 1rem 0 !important;
    h1 {
      font-size: 1.5rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
  .register-container {
    margin: 0 !important;
    flex-direction: column-reverse;
    .register-form {
      padding: 0 !important;
    }
    h2 {
      font-size: 1.4rem !important;
    }
    h3 {
      font-size: 1.2rem !important;
      .step-count {
        font-size: 0.9rem !important;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .register-form {
    .form-group {
      height: 80px !important;
    }
  }
}
@media screen and (max-width: 479px) {
  /* start of phone styles */
  .banner-container {
    height: 120px !important;
    background-position: -5rem;
  }
  .container {
    max-width: initial;
    padding: 0;
  }
  .register-content {
    padding: 1rem 0 !important;
    h1 {
      font-size: 1.5rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
  .register-container {
    margin: 0 !important;
    padding: 2rem !important;
    flex-direction: column-reverse;
    .register-form {
      padding: 0 !important;
    }
    h2 {
      font-size: 1.4rem !important;
    }
    h3 {
      font-size: 1.2rem !important;
      .step-count {
        font-size: 0.9rem !important;
      }
    }
  }
}
.react-datepicker-wrapper {
  display: block;
}

.date-picker-btn {
  border-radius: 0;
  text-align: left;
}

.tenant-signature {
  .form-check {
    display: inline;
  }
}

.custom-input {
  flex: 1;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.custom-link {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  text-decoration: none;
}

.custom-button {
  flex: 1;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.custom-button:hover {
  background-color: #0056b3;
}
