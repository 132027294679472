@import "../../../styles/colors";

.logo {
  height: 96px;
}
.navbar {
  .navbar-toggler {
    border: none;
  }
  background: $white;
  border-style: solid;
  border-width: 0px 0px 8px 0px;
  border-color: #9a1c20;
  padding: 0;
  .container {
    padding: 0 0.6rem;
  }
  .navbar-brand {
    padding: 0.6rem 0 1.2rem 0;
  }
  .navbar-collapse {
    .nav-bar-full {
      margin-top: 2.5rem;
      .navbar-nav {
        margin: 0 0.5rem;
        .btn {
          height: 36px;
        }
        .nav-link,
        .dropdown-item,
        .btn {
          font-family: "Bariol-Bold";
          text-transform: uppercase;
          font-size: 1rem;
        }
        .dropdown-item {
          .nav-link {
            &:focus-visible {
              outline: none;
            }
          }
          &.active,
          &:active {
            background-color: $white;
          }
        }
      }
    }
    &.show {
      .btn {
        margin-bottom: 0.5rem;
      }
    }
  }
}

// Responsive
@media screen and (max-width: 991px) {
    /* start of large tablet styles */
    .logo {
      height: 76px;
    }
    .nav-bar-full {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    .logo {
      height: 56px;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    .logo {
      height: 46px;
    }
  }
