@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "./colors";

$theme-colors: (
  "primary": $primary,
  "warning": $primary,
);
@import "~react-datepicker/dist/react-datepicker.css";
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.has-error {
  color: $red;
  font-size: 0.8rem;
  font-weight: 500;
}

.btn {
  border-radius: 1.5rem;
}
